import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0df493f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full text-sm mb-1 font-semibold" }
const _hoisted_3 = ["id", "name", "value"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label) + " : ", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'item-radio-' + idx,
        class: "flex items-center text-gray-700 dark:text-gray-500 mb-2"
      }, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputRadio) = $event)),
          id: item.value,
          type: "radio",
          class: "input radio-group border mr-3",
          name: 'itemRadio' + _ctx.uniqueId,
          value: item.value
        }, null, 8, _hoisted_3), [
          [_vModelRadio, _ctx.inputRadio]
        ]),
        _createElementVNode("label", {
          class: "cursor-pointer select-none",
          for: item.value
        }, _toDisplayString(item.text), 9, _hoisted_4)
      ]))
    }), 128))
  ]))
}