
import { computed, defineComponent, PropType } from "vue";
import { TextValueType } from "./types";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    items: {
      type: Array as PropType<TextValueType[]>,
      default: [],
    },
    uniqueId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, { emit }) {
    const inputRadio = computed({
      get: () => props.value,
      set: (value) => emit("update:value", value),
    });

    return {
      inputRadio,
    };
  },
});
